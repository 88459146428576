import { Component, OnInit, Input,Output,EventEmitter, AfterViewInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,AfterViewInit {
  appUser;
  AppUser;
  LoginForm: FormGroup;
  constructor(
    private auth:AuthService,
    private fb: FormBuilder,
    private router: Router,
    ) { }


  ngOnInit(): void {
   
   this.createForm()
   this.auth.loadUserCredentials()
   this.auth.getUserDetails().subscribe((user) => {
    if(user!=null){
      this.appUser = user;
      setTimeout(() => {
        this.callDropDown(); 
      }, 2000);

    }
  })
  }

loadUserData(){
  
}
ngAfterViewInit(): void {
  
}

  createForm(){
    this.LoginForm = this.fb.group({
      password: ["", Validators.required],
      username: ["", Validators.required],
    });
  }

  login() {
    var user = this.LoginForm.value;
    this.auth.logIn(user)
  }
 
  logout() {
    this.auth.logOut();
    window.location.reload();

  }

callDropDown(){

  // addEventListener("click", function() {
  //   this.classList.toggle("active");
  //  if(this.classList.contains("active")) {
  //     this.childNodes[2].style.transform="rotate(90deg)"
  // }
  //  else {
    
  //     this.childNodes[2].style.transform="rotate(270deg)"
  //   }
    
  //   });



  var dropdown = document.getElementsByClassName("dropdown-btn");
  var i;
for (i = 0; i < dropdown.length; i++) {
  dropdown[i].addEventListener("click", function() {
  this.classList.toggle("active");
 if(this.classList.contains("active")) {
    this.childNodes[2].style.transform="rotate(90deg)"
}
 else {
  
    this.childNodes[2].style.transform="rotate(270deg)"
  }
  
  });
}
}

}
