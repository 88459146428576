
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExcelComponent } from './excel/excel.component';
import {HomeComponent} from './home/home.component'
import { AuthGuard } from "./auth/auth-guard.service";
const routes: Routes = [
  {
    canActivate:[AuthGuard],

    path: "dashboard",
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)

  },

  ///////////----------  Excel upload  ----------------  //////////
  {
    canActivate:[AuthGuard],
    path: "upload/excel",
    component:ExcelComponent
    // loadChildren: () => import('./excel/excel.module').then(m => m.ExcelModule)

  },


 

   ///////////----------  Excel upload  ----------------  //////////
   {
    canActivate:[AuthGuard],

    path: "complaints/all/filter",
    loadChildren: () => import('./filter/filter.module').then(m => m.FilterModule)

  },
  ///////////----------  Upload History  ----------------  //////////
  {
    canActivate:[AuthGuard],
    path: "uploaded/complaints/all",
    loadChildren: () => import('./upload-history/all/all.module').then(m => m.AllModule)

  }, {
    canActivate:[AuthGuard],

    path: "uploaded/complaints/today",
    loadChildren: () => import('./upload-history/today/today.module').then(m => m.TodayModule)

  },

  ///////////----------  Pending  ----------------  //////////
  {
    canActivate:[AuthGuard],

    path: "complaints/pending/not-received",
    loadChildren: () => import('./pending/not-received/not-received.module').then(m => m.NotReceivedModule)

  },
  {
    canActivate:[AuthGuard],

    path: "complaints/pending/received",
    loadChildren: () => import('./pending/received/received.module').then(m => m.ReceivedModule)

  },
  {
    canActivate:[AuthGuard],

    path: "complaints/pending/unmatched",
    loadChildren: () => import('./pending/unmatched/unmatched.module').then(m => m.UnmatchedModule)

  },

  ///////////----------  Resolved ----------------  //////////
  {
    canActivate:[AuthGuard],
    
    path: "complaints/resolved/mc",
    loadChildren: () => import('./mc/mc.module').then(m => m.McModule)

  },

  {
    
    canActivate:[AuthGuard],
    path: "complaints/resolved/today",
    loadChildren: () => import('./resolved/resolved/resolved.module').then(m => m.ResolvedModule)

  },
  {
    canActivate:[AuthGuard],
    path: "complaints/resolved/all",
    loadChildren: () => import('./resolved/master-data/master-data.module').then(m => m.MasterDataModule)

  },


  ///////////----------  privates  routes  ----------------  //////////
  {
    canActivate:[AuthGuard],
    path: "users/accounts",
    loadChildren: () => import('./private/accounts/accounts.module').then(m => m.AccountsModule)

  },
  {
    canActivate:[AuthGuard],
    path: "user/signup",
    loadChildren: () => import('./private/create-accounts/create-accounts.module').then(m => m.CreateAccountsModule)

  },
  {
    canActivate:[AuthGuard],

    path: "complaints/export",
    loadChildren: () => import('./private/export/export.module').then(m => m.ExportModule)

  },

  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
  {
    path: "home",
    component: HomeComponent,
  },
  { path: "**", redirectTo: "/home" },

 

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
