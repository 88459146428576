
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {HeaderComponent} from './header/header.component'
import {ShortNamePipe} from './header/name.pipe';
import { ProcessHTTPMsgService } from './auth/process-httpmsg.service';
import {  HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor, UnauthorizedInterceptor } from './auth/auth.interceptor';
import { environment } from 'src/environments/environment';
import { DragDropDirective } from "./directives/drag-drop.directive";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "./material.module";
import { ExcelComponent } from './excel/excel.component';
import { LoaderComponent } from './loader/loader.component';
import {HomeComponent} from './home/home.component';
import {FooterComponent} from './footer/footer.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ShortNamePipe,
    DragDropDirective,
    ExcelComponent,
    LoaderComponent,
    HomeComponent,
    FooterComponent,
 
  ],
  
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [
    ProcessHTTPMsgService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    },
    {provide: 'baseURL', useValue: environment.baseURL},
  ],
  
  bootstrap: [AppComponent],
})
export class AppModule { }
