import { Component, OnInit, ElementRef } from "@angular/core";

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import * as XLSX from "xlsx";
import { Router } from "@angular/router";

import { ComplaintsService } from "../shared/complaints.service";


// AOA : array of array
type AOA = any[][];

@Component({
  selector: "app-excel",
  templateUrl: "./excel.component.html",
  styleUrls: ["./excel.component.scss"],
})
export class ExcelComponent implements OnInit {
  error: any;
  constructor(
    private el: ElementRef,
    private _formBuilder: FormBuilder,
    private complaintService:ComplaintsService,
    private router: Router
  ) { }
  Excel;

  isMaxSelect = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  currentPage = 0;
  isEmptyDrop = true;
  isExcelDrop = true;
  isRadioChecked = false;
  toppings = new FormControl();
  toppingList: string[] = [
    "Extra cheese",
    "Mushroom",
    "Onion",
    "Pepperoni",
    "Sausage",
    "Tomato",
    "Extra cheese",
    "Mushroom",
    "Onion",
  ];

  states: string[] = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
  ];

  email = new FormControl("", [Validators.required, Validators.email]);

  /**
   * sheet.js
   */
  origExcelData: AOA = [
    ["Data: 2018/10/26"],
    ["Data: 2018/10/26"],
    ["Data: 2018/10/26"],
  ];
  refExcelData: Array<any>;
  excelFirstRow = [];
  excelDataEncodeToJson;
  excelTransformNum = [];

  /** Default 的 excel file-name 文字 */
  sheetJsExcelName = "null.xlsx";

  /* excel sheet.js */
  sheetCellRange;
  sheetMaxRow;
  localwSheet;
  localWorkBook;
  localPDF;
  sheetNameForTab: Array<string> = ["excel tab 1", "excel tab 2"];
  totalPage = this.sheetNameForTab.length;
  selectDefault;
  sheetBufferRender;

  pdfFile;
  pdfSrc;
  pdfBufferRender;

  checkboolean = false;
  excelvalidate;
  checkvalid;
  secondrefE;
  excelDataEncodeOld;
  strArray = [];
  cechduplicate = true;
  duplicate;
  compCount;
  cocaCount;
  validate = {
    A: "S.No",
    B: "Notification date",
    C: "Work center",
    D: "Order",
    E: "Customer Name",
    F: "Address",
    G: "Long Text",
    H: "CRMID",
    I: "Contact person",
    J: "Telephone 1",
    K: "Distributor",
    L: "CWS",
    M: "Equipment",
    N: "City Name",
    O: "Caller No",
    P: "(3P) Receive Date",
    Q: "Town",
    R: "Tech Name",
    S: "Bar Code",
    T: "Asset Model",
    U: "Fault & Diagnosing",
    V: "Remedy/Technician Remarks",
    W: "Main Category",
    X: "Sub Category",
    Y: "Resolve Date",
    Z: "Status",
    AA: "Contact Person",
    AB: "Contact Number",
    AC: "Compressor No",
    AD: "Final Barcode",
    AE: "Level",
    AF: "Report Date & Time",
  };

  inputExcelOnClick(evt) {
    const target: HTMLInputElement = evt.target;
    if (target.files.length === 0) {
      throw new Error("未上傳");
    }
    if (target.files.length > 1) {
      throw new Error("Cannot use multiple files");
    }
    this.sheetJsExcelName = evt.target.files.item(0).name;
    const reader: FileReader = new FileReader();
    this.readerExcel(reader);
    reader.readAsArrayBuffer(target.files[0]);
    this.sheetBufferRender = target.files[0];
    this.isEmptyDrop = false;
    this.isExcelDrop = true;
  }

  /** 解析excel ,from DragDropDirective , TODO: 用 <ng-template> 判斷 t/f
   *  DragDropDirective 處理 drop event, 檔名過濾.
   * @  回傳 excel 結構{readAsArrayBuffer}
   */
  dropExcelOnChance(targetInput: Array<File>) {
    this.sheetJsExcelName = targetInput[0].name;
    if (targetInput.length !== 1) {
      throw new Error("Cannot use multiple files 觸發條跳視窗");
      /* TODO: 觸發條跳視窗 */
    }
    const reader: FileReader = new FileReader();
    this.readerExcel(reader);
    reader.readAsArrayBuffer(targetInput[0]);
    this.sheetBufferRender = targetInput[0];
    this.isEmptyDrop = false;
    this.isExcelDrop = true;
  }

  dropExcelBlock(fileList: Array<File>) {
    if (fileList.length === 0) {
      return;
    } else {
      this.isExcelDrop = false;
      throw new Error("被擋掉的檔案 觸發條跳視窗");
      /* TODO: 觸發彈跳視窗 */
    }
  }

  /**
   *  解析excel , from button event , 點擊 tab 切換分頁
   * @returns回傳 excel 結構{readAsArrayBuffer}
   */
  loadSheetOnTabClick(index: number) {
    this.currentPage = index;
    /* 過濾例外 */
    if (this.localWorkBook === undefined) {
      throw new Error("需要處理空值點擊的例外");
      return;
    }
    /* onload from this.localWorkBook, reReader from this.sheetBufferRender*/
    const reader: FileReader = new FileReader();
    this.readerExcel(reader, index);
    reader.readAsArrayBuffer(this.sheetBufferRender);
  }
  cocaCount2;
  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ["", Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required],
    });

  }

  getErrorMessage() {
    return this.email.hasError("required")
      ? "You must enter a value"
      : this.email.hasError("email")
        ? "Not a valid email"
        : "";
  }

  onClickRadioExcel() {
    if (this.localWorkBook === undefined) {
      throw new Error("需要處理空值點擊的例外");
      return;
    }
    this.isExcelDrop = true;
    this.isEmptyDrop = false;
  }

  consoleHeight(evt) {
    if (evt.panel.nativeElement.clientHeight >= 255) {
      this.isMaxSelect = true;
    } else {
      this.isMaxSelect = false;
    }
  }

  transform(value) {
    return (
      (value >= 26 ? this.transform(((value / 26) >> 0) - 1) : "") +
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[value % 26 >> 0]
    );
  }

  readerExcel(reader, index = 0) {
    /* reset array */
    this.origExcelData = [];
    reader.onload = (e: any) => {
      const data: string = e.target.result;
      const wBook: XLSX.WorkBook = XLSX.read(data, { type: "array" });
      this.localWorkBook = wBook;
      const wsname: string = wBook.SheetNames[index];
      this.sheetNameForTab = wBook.SheetNames;
      this.totalPage = this.sheetNameForTab.length;
      this.selectDefault = this.sheetNameForTab[index];
      const wSheet: XLSX.WorkSheet = wBook.Sheets[wsname];
      this.localwSheet = wSheet;
      this.sheetCellRange = XLSX.utils.decode_range(wSheet["!ref"]);
      this.sheetMaxRow = this.sheetCellRange.e.r;
      this.origExcelData = <AOA>XLSX.utils.sheet_to_json(wSheet, {
        header: 1,
        range: wSheet["!ref"],
        raw: true,
      });

      this.refExcelData = this.origExcelData
        .slice(0)
        .map((value) => Object.assign([], value));

      /* 抓 range & 清除占存 A->Z */
      this.excelTransformNum = [];
      for (let idx = 0; idx <= 31; idx++) {
        this.excelTransformNum[idx] = this.transform(idx);
      }

      /* 加入 order 的佔位(#) */
      this.refExcelData.map((x) =>
        // createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        x.unshift('s')
      );
      this.excelTransformNum.unshift("createdAt");
      this.refExcelData.map((x) => x.unshift("#"));
      this.excelTransformNum.unshift("order");
      // this.refExcelData.map(x => x.push(firebase.database.ServerValue.TIMESTAMP));
      // this.excelTransformNum.push('coca_time');

      /* 合併成JSON */
      this.excelvalidate = this.refExcelData.slice(0, 1).map((item) =>
        item.reduce((obj, val, i) => {
          obj[this.excelTransformNum[i]] = val;
          return obj;
        }, {})
      );

      delete this.excelvalidate[0].order;
      delete this.excelvalidate[0].createdAt;
      this.checkvalid = this.excelvalidate[0];

      var compareObjects = function (o1, o2) {
        for (var p in o1) {
          if (o1.hasOwnProperty(p)) {
            if (
              o1[p] == "(3P) Receive Date" ||
              o1[p] == "City Name" ||
              o1[p] == "Contact Person"
            ) {
            } else if (o1[p] != o2[p]) {
              return false;
            }
          }
        }
        for (var p in o2) {
          if (o2.hasOwnProperty(p)) {
            if (
              o1[p] == "(3P) Receive Date" ||
              o1[p] == "City Name" ||
              o1[p] == "Contact Person"
            ) {
            } else if (o1[p] !== o2[p]) {
              return false;
            }
          }
        }
        return true;
      };

      if (compareObjects(this.validate, this.checkvalid)) {
        this.checkboolean = true;
      }

      function ExcelDateToJSDate(serial) {
        var utc_days = Math.floor(serial - 25569);
        var utc_value = utc_days * 86400;
        var date_info = new Date(utc_value * 1000);

        var fractional_day = serial - Math.floor(serial) + 0.0000001;

        var total_seconds = Math.floor(86400 * fractional_day);

        var seconds = total_seconds % 60;

        total_seconds -= seconds;

        var hours = Math.floor(total_seconds / (60 * 60));
        var minutes = Math.floor(total_seconds / 60) % 60;

        return new Date(
          date_info.getFullYear(),
          date_info.getMonth(),
          date_info.getDate(),
          hours,
          minutes,
          seconds
        ).toLocaleDateString();
      }

      //for view
      this.excelDataEncodeOld = this.refExcelData.slice(1).map((item) =>
        item.reduce((obj, val, i) => {
          obj[this.excelTransformNum[i]] = val;

          delete obj[this.excelTransformNum[0]];
          if (i == 1) {
            delete obj[this.excelTransformNum[i]];
          }
          if (i == 3) {
            obj[this.excelTransformNum[i]] = ExcelDateToJSDate(
              obj[this.excelTransformNum[i]]
            );
          }

          return obj;
        }, {})
      );

      this.excelDataEncodeToJson = this.refExcelData.slice(1).map((item) =>
        item.reduce((obj, val, i) => {
          obj[this.excelTransformNum[i]] = val;

          if (i == 3) {
            obj[this.excelTransformNum[i]] = ExcelDateToJSDate(
              obj[this.excelTransformNum[i]]
            );
            //console.log()
          }
          if (i == 5) {
            this.strArray.push(obj[this.excelTransformNum[i]]);
          }

          return obj;
        }, {})
      );
      this.findduplicateorder();
    };
  }
  findduplicateorder() {
    let findDuplicates = (arr) =>
      arr.filter((item, index) => arr.indexOf(item) != index);
    var checkdup = [...new Set(findDuplicates(this.strArray))];

    if (checkdup.length !== 0) {
      this.duplicate = [...new Set(findDuplicates(this.strArray))];
      this.cechduplicate = false;
    }
  }
  duplicateArr = [];
  notDupli = [];
  dupli = true;

  proceedSend() {
    this.notDupli.forEach((element) => {
      // this.complaintService.SendComplaint(
      //   element,
      //   element.Order_No,
      //   element.srNo
      // );
      // this.complaintService.CreateCocaComplaints(
      //   element,
      //   element.Order_No,
      //   element.srNo
      // );
    });

    // this.complaintService.updateOneCount("Conew", this.cocaCount2);
    this.router.navigate(["/complaints"]);
  }
  finalObjects=[]
  Send() {
    if (this.cechduplicate) {
      if (this.checkboolean) {
        let res = confirm("Are you want to send complaints?");
        if (res) {
          const transformed = this.excelDataEncodeToJson.map(
            ({
              A: sr_no,
              B: notification_date,
              C: work_center,
              D: order_no,
              E: customer_name,
              F: address,
              G: long_text,
              H: crmid,
              I: contact_person,
              J: telephone,
              K: distributor,
              L: cws,
              M: equipment,
              N: city_name,
              O: caller_no,
              order: order,
            }) => {
            
                return  {
                  sr_no,
                  notification_date,
                  work_center,
                  order_no,
                  customer_name,
                  address,
                  long_text,
                  crmid,
                  contact_person,
                  telephone,
                  distributor,
                  cws,
                  equipment,
                  city_name,
                  caller_no,
                  order,
                }
            } 
           
            
          );
         
        let finalArray =   transformed.filter(item=> item.order_no != undefined)
        finalArray.forEach((complaint, i) => {
    
            if (complaint.cws === undefined) {
              complaint.cws = "";
            }
            this.complaintService.postComplaint(complaint)
            .then(res=>{
              this.totalUpload  = this.totalUpload + 1;
              this.processUpload(finalArray)
              
            }).catch(error=>{
           
                this.notDupli.push(complaint.order_no);
              
            })
         
          });
        }
      } else {
        alert("Please validate the file");
      }
    } else {
      alert("Please remove the Duplicate Order " + this.duplicate);
    }
  }

  totalUpload = 0;
  processUpload(finalArray){
    
    if(this.totalUpload === finalArray.length){
      this.router.navigate(['/dashboard']);
    }
   
  }
  
}
